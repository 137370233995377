import React, { useState } from 'react';
import { Image } from '../styledComponent';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as CloseMenu } from '../../assets/images/x.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavItem,
  NavbarToggler,
  Container,
} from 'reactstrap';
// Importing Image
import logodark from '../../assets/images/logo-dark.png';

export const NavbarPage = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  if (click) {
    document.body.setAttribute('style', `position: fixed; `);
  } else {
    document.body.setAttribute('style', `position: relative; `);
  }
  return (
    <React.Fragment>
      <Navbar style={{backgroundColor: "#1d3557"}}>
        <Container>
          <NavbarBrand>
            <HashLink to='/#'>
              <Image src={logodark} alt='' />
            </HashLink>
          </NavbarBrand>
          <div className={click ? 'overlay' : null}>
            <NavbarToggler onClick={handleClick}>
              {' '}
              {click ? (
                <CloseMenu className='menu-icon' />
              ) : (
                <MenuIcon
                  className='menu-icon'
                  style={{ marginTop: '-30px', position: 'fixed', right: '0', zIndex: "99999" }}//absolute
                />
              )}
            </NavbarToggler>

            <Nav
              className={click ? 'nav-options active ' : 'nav-options'}
              onClick={closeMobileMenu}
            >
              <NavItem className='option' onClick={closeMobileMenu}>
                <HashLink to='/' className='hashlink'>
                  Home
                </HashLink>
              </NavItem>
              <NavItem className='option' onClick={closeMobileMenu}>
                <HashLink to='/#service' className='hashlink'>
                  Service
                </HashLink>
              </NavItem>
              <NavItem className='option' onClick={closeMobileMenu}>
                <HashLink to='/#clients' className='hashlink'>
                  Clients
                </HashLink>
              </NavItem>
              <NavItem className='option' onClick={closeMobileMenu}>
                <HashLink to='/career' className='hashlink'>
                  Careers
                </HashLink>
              </NavItem>
              {/* <NavItem className='option' onClick={closeMobileMenu}>
                <HashLink to='' className='hashlink'>
                  About
                </HashLink>
              </NavItem> */}
              <NavItem className='option' onClick={closeMobileMenu}>
                <HashLink to='#contact-us' className='hashlink'>
                  Contact Us
                </HashLink>
              </NavItem>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Contact } from "./components/footer/Contact";
import { SubFooter } from "./components/footer/Subfooter";
import { Footer } from "./components/footer/footer";
import { NavbarPage } from "./components/Navbar/Navbar";
import "./App.css";

// import { HomePage } from './Page/HomePage';
// import { CareerPage } from './Page/CareerPage';
import { Spinner } from "reactstrap";

const HomePage = React.lazy(() => import("./Page/HomePage"));
const CareerPage = React.lazy(() => import("./Page/CareerPage"));

function App() {
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div >
              <Spinner
                
                style={{
                  width: "3rem",
                  height: "3rem",
                  position: "relative",
                  textAlign: "center",
                  margin: "400px 900px",
                }}
                color="primary"
              />
            </div>
          }
        >
          <NavbarPage />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/career" exact component={CareerPage} />
          </Switch>
          <Contact />
          <SubFooter />
          <Footer />{" "}
        </Suspense>
      </Router>
    </>
  );
}

export default App;

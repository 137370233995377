import React, { useState, Suspense } from 'react';
import { Container, Row, Col, Input, Form, FormGroup } from 'reactstrap';
import { Button } from '../styledComponent';
import { ContactValidation } from './ContactValidation';
import Airtable from 'airtable';
export const ContactModal = ({ setModal }) => {
  const [state, setState] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleSend = (e) => {
    e.preventDefault();

    setErrors(ContactValidation(state));
    var base = new Airtable({ apiKey: 'keyki5Izni3sznVwb' }).base('appfpeSukTW8y1c0C');
    base('TR Table').create([
      {
        "fields": {
          "Full Name": state.name,
          "email": state.email,
          "message": state.message,
          "mobile": state.mobile
        }
      },
    ], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        setModal(false)
        setState({
          name: '',
          mobile: '',
          email: '',
          message: '',
        })
        console.log(record.getId());
      });
    });

    console.log(state, errors);
  };
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading ...</div>}>
        <Container>
          <div className='form'>
            <div id='message'></div>

            <Form name='contact-form' id='contact-form' onSubmit={handleSend}>
              <Row>
                <Col lg={12}>
                  <FormGroup className='app-label'>
                    <Input
                      name='name'
                      id='name'
                      type='text'
                      value={state.name}
                      onChange={(e) =>
                        setState({ ...state, name: e.target.value })
                      }
                      className='form-control'
                      placeholder='Full Name'
                    />
                    {errors.name ? (
                      <p className='error_message'>{errors.name}</p>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup className='app-label'>
                    <Input
                      name='mobile'
                      id='mobile'
                      type='number'
                      value={state.mobile}
                      onChange={(e) =>
                        setState({ ...state, mobile: e.target.value })
                      }
                      className='form-control'
                      placeholder='Mobile'
                    />
                    {errors.mobile ? (
                      <p className='error_message'>{errors.mobile}</p>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className='app-label'>
                    <Input
                      name='email'
                      id='email'
                      type='email'
                      value={state.email}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                      className='form-control'
                      placeholder='Email'
                    />
                    {errors.email ? (
                      <p className='error_message'>{errors.email}</p>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormGroup className='app-label'>
                    <Input
                      type='textarea'
                      name='comments'
                      id='comments'
                      rows='4'
                      value={state.message}
                      onChange={(e) =>
                        setState({ ...state, message: e.target.value })
                      }
                      className='form-control'
                      placeholder='Message'
                    />
                    {errors.message ? (
                      <p className='error_message'>{errors.message}</p>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Col lg={12}>
                <Button style={{ marginLeft: '-5px' }} onClick={handleSend}>
                  SEND
                </Button>
              </Col>
            </Form>
          </div>
        </Container>
      </Suspense>
    </React.Fragment>
  );
};

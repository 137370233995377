import styled from "styled-components";
// import ''
export const Image = styled.img`
  display: flex;
  width: 133px;
  height: 50px;
  overflow: hidden;
  float: start;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -200px;
  @media screen and (max-width: 1500px) {
    margin-left: 0;
    left: 0;
  }
`;
/* Home Section Style */

export const MainTitle = styled.h1`
  width: 550px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  letter-spacing: 0px;
  line-height: 1.2;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--silver, #45454a);
  text-align: left;
  @media screen and (max-width: 1500px) {
    width: 300px;
    font-size: 36px;
    margin-left: 0;
    left: 0;
  }
`;

export const BuidingImage = styled.img`
  width: 100%;
  height: 30vh;
  overflow: visible;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 750px) {
    display: none;
  }
`;
export const Button = styled.button`
  color: #fff;
  background-color: #ff493d;
  box-sizing: border-box;
  border: none;
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
  cursor: pointer;
`;
export const SectionTitle = styled.h1`
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 100px;
  @media screen and (max-width: 840px) {
    padding-top: 50px;
    font-size: 24px;
  }
`;

export const SectionText = styled.p`
  width: auto;
  height: auto;
  overflow: visible;
  letter-spacing: 0px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: #000000;
  @media screen and (max-width: 840px) {
    font-size: 18px;
  }
`;

export const Para = styled.div`
  margin-left: 10px;
  @media (min-width: 500px) and (max-width: 840px) {
    margin-left: auto;
    margin-top: 50px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 0px;
  }
`;
export const Para2 = styled.div`
  margin-left: 0px;
  padding-left: 30px;
  @media (min-width: 500px) and (max-width: 840px) {
    margin-left: auto;
    margin-top: 50px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 0px;
  }
`;
export const ParaTitle = styled.h5`
  width: 100%;
  height: 40px;
  overflow: visible;
  letter-spacing: 0px;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--think, #ff493d);
`;
export const ParaTitle2 = styled.h5`
  width: 100%;
  height: 40px;
  overflow: visible;
  letter-spacing: 0px;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--think, #ff493d);
`;
export const MobileParaTitle = styled.h5`
  width: 100%;
  overflow: visible;
  letter-spacing: 0px;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--think, #ff493d);
  text-align: center;
`;
export const ParaText = styled.p`
  width: 369px;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  letter-spacing: 0px;
  text-align: justify;
  text-justify: inter-word;
  font-size: 20px;
  color: #000000;
  @media screen and (max-width: 840px) {
    width: auto;
    font-size: 18px;
    margin: 10px 20px !important;
  }
`;
export const ParaText2 = styled.p`
  width: 369px;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  letter-spacing: 0px;
  text-align: justify;
  text-justify: inter-word;
  font-size: 20px;
  color: #000000;
  @media screen and (max-width: 840px) {
    font-size: 18px;
    margin: 10px 20px !important;
  }
`;

/* Hiring Section style */
export const HiringCardImage = styled.img`
  padding-top: 10px;
  margin: 0 auto;
  width: 185.07px;
  height: 141.07px;
  display: block;
`;

export const Scroller = styled.div`
  width: 1280px;
  margin-top: 50px;
  overflow: visible;
`;
export const Scroller2 = styled.div`
  width: 1140px;
  overflow: visible;
`;

export const HiringCard = styled.div`
  width: 325px;
  height: 370px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: var(--w, #ffffff);
  overflow: visible;
  margin: 15px;
  border-radius: 10px;
  margin-right: 10px;
  text-align: center;
  align-items: center;

  @media screen and (max-width: 840px) {
    display: grid;
    margin: 0;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 270px;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  flex-shrink: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 94%;
  border: 5px solid #ffffff;
  border-radius: 10px;
  margin: 0 1rem;
  & > ${Content} {
    margin: 10px 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 50%;
`;

export const ArrowButton = styled.button`
  border: none;
  color: #000000;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 700;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 840px) {
    padding: 5px 10px;
    margin: 0 5px;
  }
`;

export const HiringTitle = styled.h1`
  width: auto;
  height: auto;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  letter-spacing: 0px;
  line-height: 1;
  color: var(--think, #45454a);
  font-size: 36px;
  /* margin-bottom: 20px; */
  margin-left: auto;
  @media screen and (max-width: 840px) {
    margin-left: 0px;
    font-size: 30px;
  }
`;

export const HiringCardText = styled.h1`
  width: 254px;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--think, #ff493d);
  letter-spacing: 0px;
  line-height: 1;
  font-size: 24px;
  text-align: center;
  margin: 30px;
  padding-top: 40px;
  margin-left: 40px;
  @media screen and (max-width: 840px) {
    padding-top: 0px;
    margin-top: 0px;
  }
`;

export const HiringCardPara = styled.p`
  width: 270px;
  overflow: hidden;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  letter-spacing: 0px;
  line-height: 1;
  font-size: 18px;
  text-align: center;
  color: var(--silver, #45454a);
  margin: 30px;
  padding-bottom: 30px;
`;

export const Icon = styled.div`
  margin-left: auto;
  margin-top: -5px;
  text-align: end;
  font-size: 25px;
  color: #333;
  @media screen and (max-width: 840px) {
    color: #fff;
  }
`;

/* Client Section style */
export const ClientCard = styled.div`
  width: auto;
  height: auto;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  background-image: url("../assets/images/Vector.svg");
  overflow: hidden;
  border-radius: 10px;
  margin: 0 35px 20px;
  padding: 10px 20px;
  @media screen and (max-width: 840px) {
    margin: 0 35px;
    width: auto;
  }
`;

export const ClientParaText = styled.p`
  width: auto;
  overflow: hidden;
  color: var(--silver, #45454a);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.5;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  text-align: center;
  margin-left: 33px;
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: justify;
  @media screen and (max-width: 840px) {
    margin: 30px 0px;
    width: auto;
  }
`;

export const ClientName = styled.h6`
  overflow: visible;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--silver, #45454a);
`;

export const ClientPost = styled.p`
  overflow: visible;
  font-weight: 300;
  font-style: normal;
  font-family: "Montserrat", serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  color: var(--think, #ff493d);
  margin-bottom: 0.5rem;
`;

export const ClientLocation = styled.p`
  overflow: visible;
  font-weight: 300;
  font-style: normal;
  font-family: "Montserrat", serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  color: var(--think, #777777);
  margin-bottom: 0.5rem;
`;

export const ClientImage = styled.img`
  width: 92px;
  height: 92px;
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
  float: left;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 15px;
`;
/* Contact */
export const FooterTitle = styled.h3`
  width: auto;
  height: auto;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--think, #ff493d);
  letter-spacing: 0px;
  line-height: 1;
  font-size: 30px;
  margin-left: 25px;
  @media screen and (max-width: 840px) {
    font-size: 36px;
    margin-left: 10px;
  }
`;

export const ModalTitle = styled.h1`
  display: none;
  width: auto;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--think, #ff493d);
  letter-spacing: 0px;
  line-height: 1;
  font-size: 36px;
  margin-left: 30px;
  @media screen and (max-width: 840px) {
    display: block;
  }
`;

/* Career Section style */
export const CareerTitle = styled.h1`
  width: 664px; 
  height: auto
  overflow: hidden;
  font-weight: 500;
  font-style: normal;
  font-family: 'Montserrat', serif;
  color: var(--think, #45454a);
  letter-spacing: 0px;
  line-height: 1;
  font-size: 36px;
  margin-left: -50px;
  margin-bottom: 40px;
  @media screen and (max-width: 840px) {
    width: auto;
    font-size: 30px;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 50px;
  }
`;

export const CareerText = styled.p`
  width: 750px;
  overflow: hidden;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: var(--silver, #45454a);
  letter-spacing: 0px;
  line-height: 1;
  margin-left: -50px;
  font-size: 24px;
  @media screen and (max-width: 840px) {
    width: auto;
    line-height: 1;
    font-size: 18px;
    margin-left: 0 !important;
    margin-top: 30px;
  }
`;

export const Text = styled.p`
  width: 183px;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1;
  margin-top: 80px;
  @media screen and (max-width: 840px) {
    font-size: 18px;
  }
`;

export const Aasset = styled.img`
  width: 458px;
  margin-top: 50px;

  margin-left: 150px;
  @media screen and (max-width: 840px) {
    width: 336px;
    float: right;
    margin-top: -600px;
    margin-right: -70px;
    overflow: hidden;
  }
`;

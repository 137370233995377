import React from "react";
// import { Row, Col, Container } from 'reactstrap';

export const Footer = () => {
  return (
    <React.Fragment>
      {/* <section className='footer-last'>
        <Container>
          <Row className='mt-5'>
            <Col }>
              <div className='footer-link'>
                <p>
                  © Copyright 2021{' '}
                  <span className='footer-span'>
                    
                    ThinkReal{' '}
                    <span className='footer-span'> All Rights Reserved</span>
                  </span>
                </p>
              </div>
            </Col>

          </Row>
        </Container>
      </section> */}
      <section className="ft-legal">
        <ul className="ft-legal-list">
          <li>&copy; Copyright 2021</li>
          <li style={{ color: "#ffffff" }}>
            Think Real Software Solutions Pvt Ltd &nbsp; All Rights Reserved
          </li>
        </ul>
      </section>
    </React.Fragment>
  );
};

export const ContactValidation = (state,file) => {
  let errors = {};

  if (!state.name) {
    errors.name = 'Name required';
  }

  if (!state.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(state.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!state.mobile) {
    errors.mobile = 'Mobile No. is required';
  }
  if (!state.message) {
    errors.message = 'Message is required';
  }
  if (!state.level) {
    errors.level = 'Level is required';
  }
  if (!state.value) {
    errors.value = 'Post is required';
  }
  return errors;
};

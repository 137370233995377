import React from "react";
import { Container, Row, Col } from "reactstrap";
import logodark from "../../assets/images/logo-dark.png";
import { HashLink } from "react-router-hash-link";

export const SubFooter = () => {
  // const state = {
  //     links: [
  //         {
  //             id: 0,
  //             title: 'SERVICES',
  //             target: '/#service',

  //             child: [
  //                 { title: 'Web Development', link: '/#development' },
  //                 { title: 'Mobile Development', link: '/#development' },
  //                 { title: 'Data Annotation', link: '/#annotation' },
  //             ],
  //         },
  //         {
  //             id: 1,
  //             title: 'PRODUCTS',
  //             target: '/#product',
  //             child: [
  //                 { title: 'Society Management Solutions', link: '/#society' },
  //                 { title: 'Ecommerce Solutions', link: '/#ecommerce' },
  //             ],
  //         },
  //         {
  //             id: 2,
  //             title: 'ABOUT US',
  //             target: '',
  //             child: [
  //                 { title: 'Contact Us', link: '/#contact-us' },
  //                 // { title: 'Blogs', link: '/' },
  //                 { title: 'Careers', link: '/career' },
  //                 { title: 'Clients', link: '/#clients' },
  //                 // { title: 'Sitemap', link: '/' },
  //             ],
  //         },
  //     ],
  // };
  // const topScroll = () => {
  //     document.body.scrollTop = 0; // For Safari
  //     document.documentElement.scrollTop = 0;
  // }
  return (
    <React.Fragment>
      <section id="contact-us" className="footer-background main-footer">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="mb-4">
                <HashLink to="/#">
                  <img
                    src={logodark}
                    alt=""
                    className="logo-dark"
                    height="50"
                  />
                </HashLink>

                {/* <div className='sub-footer-links'>
                                    <a href='https://www.facebook.com'>
                                        <FaFacebook className='icons' />
                                    </a>{' '}
                                    <a href='https://www.linkedin.com'>
                                        <FaLinkedin className='icons' />
                                    </a>{' '}
                                    <a href='https://twitter.com'>
                                        <FaTwitter className='icons' />
                                    </a>{' '}
                                </div> */}
              </div>
            </Col>
            {/* <Col lg={9} sm={12}>
                            <Row>
                                <p className='footer-section mt-4 mb-2'>
                                    Address: #406, basement floor 1st c cross 7th block
                                    koramangala, bangalore, 560095
                                </p>
                            </Row><Row>
                                <p className='footer-section font-weight-normal'>
                                    Phone: +91 9845995781
                                </p>
                                <p className='footer-section mt-4 mb-2'>
                                    Email:{' '}
                                    <a style={{ color: 'red' }} href='mailto: info@thinkreal.in'>
                                        info@thinkreal.in
                                   </a>
                                </p>
                            </Row>
                        </Col> */}
            {/* <Col lg={5} sm={3}>
                            
                        </Col> */}
            <Col lg={9} sm={12}>
              <Row>
                <Col md={6} sm={6}>
                  <p className="footer-section mt-2 mb-2">
                    <p>Think Real Software Solutions Pvt Ltd</p>
                    <p className="cin-number">CIN : U72900KA2020PTC134960</p>
                    <p className="cin-number">DUNS: 856834249</p>
                  </p>
                  <p className="footer-section mt-2 mb-2">
                    Address: #406, basement floor 1st c cross 7th block
                    koramangala, bangalore, 560095
                  </p>
                </Col>
                <Col md={5} sm={6}>
                  <p className="footer-section font-weight-normal">
                    Phone: +919845995782
                  </p>
                  <p className="footer-section  mb-2">
                    Email:{" "}
                    <a
                      style={{ color: "red" }}
                      href="mailto: hannan@thinkreal.in "
                    >
                      hannan@thinkreal.in
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
      </section>
    </React.Fragment>
  );
};

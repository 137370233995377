import React, {useState, useEffect} from "react";
import { useLocation } from "react-router";
import { Container, Row, Col } from "reactstrap";
import { ContactModal } from "../Career/ContactModal";
import { FooterTitle } from "../styledComponent";

export const Contact = () => {
  const [bgcolor, setBgcolor] = useState("");
  const location = useLocation();
  useEffect(() => {    
    const color = location.pathname==="/career"?"#ffffff":"#c9e7f8";
    setBgcolor(color);
  }, [location.pathname])
  
  return (
    <React.Fragment>
      <section
        id="contact-us"
        className="footer-bg-test"
        style={{backgroundColor: bgcolor}}
        
      >
        <Container>
          <div className="footer-content">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <FooterTitle>Have Questions? Write to Us.</FooterTitle>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <ContactModal />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};
